@import '../../../../styles/styles.scss';

.container-form {
  .form {
    .section-form {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;

      @media (min-width: $xx-large) {
        gap: 31px;
      }

      &__aside {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;

        .label_email {
          opacity: 0.5;
        }

        input {
          .input_disabled {
            padding: 0px 8px 0px 20px;
          }

          &.input_email {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        input:disabled {
          background-color: transparent;
          color: #6429cd;
          pointer-events: none;
          padding: 0px;
          border: none;
          opacity: 1;
          font-size: clamp(1.2rem, 2vw, 1.4rem);
          font-weight: 500;
        }

        @media (max-width: $small) {
          flex-direction: column;
          gap: 18px;
        }

        &--doble-side {
          display: flex;
          justify-content: space-between;
          width: 100%;
          column-gap: 8px;
          @media (min-width: $x-large) {
            flex-direction: row;
            justify-content: space-between;
          }
          &-A {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-end;
            @media (min-width: $medium) {
              max-width: 160px;
            }
            @media (min-width: $x-large) {
              min-width: 130px;
              width: 100%;
            }
          }
          &-B {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-end;
            @media (min-width: $x-large) {
              min-width: 130px;
              width: 100%;
            }
          }
        }
      }

      @media (min-width: $high-quality) {
        input {
          font-size: 1.4rem;
        }
      }

      @media (min-width: $high-quality) {
        .label {
          font-size: 1.6rem;
        }
      }

      .section-form__select:disabled {
        background-color: transparent !important;
        color: #6429cd !important;
        pointer-events: none !important;
        border: none !important;
        opacity: 1 !important;
        font-weight: 400 !important;
        cursor: not-allowed !important;
      }

      &__article {
        width: 100%;
        max-height: 100%;
      }

      .container-select {
        &__input {
          svg {
            display: none;
          }
        }
      }

      .group-elements {
        display: grid;
        grid-auto-flow: column;
        align-items: flex-end;
        justify-content: space-between;
        grid-template-columns: 40% auto;
        gap: 16px;
      }

      &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
        margin-top: 40px;
        @media (min-width: $medium) {
          margin-top: 60px;
        }
        @media (min-width: $x-large) {
          margin-top: 60px;
          column-gap: 5rem;
        }
        &--left {
          display: flex;
          width: 100px;
        }
        &--right {
          display: flex;
          width: 100px;
        }
      }

      &__error-paragraph {
        display: flex;
        align-items: center;
        width: 100%;
        color: #660019;
        font-size: 1.2rem;
        text-align: left;
        margin-top: 0.9rem;

        &--disabled {
          display: flex;
          align-items: center;
          width: 100%;
          color: #660019;
          font-size: 1.2rem;
          text-align: left;
          margin-top: 0.9rem;
          opacity: 0.4;
        }
      }

      &__error-icon {
        margin-right: 0.7rem;
      }

      .a_date {
        &__input {
          &--error {
            border: 1px solid #b8002c;
            box-shadow: 0px 0px 10px 0px #ed00394d inset;
          }
        }
      }
    }
  }
}
