
.alert{
    background-color: #9610FF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: inherit;
    margin: 0 auto;
    height: 64px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px;
    box-shadow: 0px 10px 20px rgb(51, 0, 114, 0.6);
}

.alertMessage{
    display: flex;
    align-items: center;
    gap: 12px;
    &__text{
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;

    }
}