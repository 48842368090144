@import '../../../styles/styles.scss';

%buttonPrimaryProps {
    border: none;
    border-radius: 999px;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 8px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: unset;
    transition: 0.3s ease;
}

.searchBar {
    &__title {
        display: flex;
        align-items: center;
        padding-top: 3rem;
        gap: 25px;

        button {
            background-color: $color-primary;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.9rem;
            width: 2.99rem;
            border-radius: 50%;
            border: none;
            cursor: pointer;
        }

        h1 {
            padding-top: 0px;
            padding-left: 0px;
            margin-bottom: 0px;
        }
    }

    &__form {
        margin-top: 32px;

        label {
            display: block;
            color: $color-purple;
            font-family: $font-poppins;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        &--field {
            width: 50%;
            display: flex;
            gap: 24px;
        }
    }

    &__button {
        @extend %buttonPrimaryProps;
        background-color: #6429cd;
        color: white;
    }
}