@import 'styles/styles.scss';

.support-boundary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 5vw;
  background: $background-gradient;
  line-height: 33px;
  box-shadow: 0px 0px 60px 0px #6429CD99 inset;
  position: relative;

  .decorative__stroke {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; 

    &__left {
      left: 0;
      top: 70%;
      width: clamp(300px, 40vw, 771px);
      height: clamp(150px, 15vw, 200px);
    }
    
    &__right {
      right: 0;
      top: 20%;
      width: clamp(350px, 40vw, 871px);
      height: clamp(144px, 50vw, 164px);
      
      @include desktop {
        top: 25%;
      }
    }
  }

  &__eMantenimiento {
    width: 100%;
  }

  &__span {
    display: hidden;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    position: absolute;
    animation: span 2s;
    animation-fill-mode: forwards;

    &-container {
      position: absolute;
      z-index: 1;
      display: flex;
      top: 0;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

    &:nth-child(1) {
      width: 35vw;
      height: 35vw;
    }
    &:nth-child(2) {
      width: 42vw;
      height: 42vw;
    }
    &:nth-child(3) {
      width: 50vw;
      height: 50vw;
    }
    &:nth-child(4) {
      width: 58vw;
      height: 58vw;
    }
    &:nth-child(5) {
      width: 67vw;
      height: 67vw;
    }
    &:nth-child(6) {
      width: 78vw;
      height: 78vw;
    }
    &:nth-child(7) {
      width: 90vw;
      height: 90vw;
    }
    &:nth-child(8) {
      width: 97vw;
      height: 97vw;
    }
    &:nth-child(9) {
      width: 109vw;
      height: 109vw;
    }
    &:nth-child(10) {
      width: 120vw;
      height: 120vw;
    }
    &:nth-child(11) {
      width: 132vw;
      height: 132vw;
    }

    @include tablet {
      &:nth-child(1) {
        width: 20vw;
        height: 20vw;
      }
      &:nth-child(2) {
        width: 25vw;
        height: 25vw;
      }
      &:nth-child(3) {
        width: 30vw;
        height: 30vw;
      }
      &:nth-child(4) {
        width: 37vw;
        height: 37vw;
      }
      &:nth-child(5) {
        width: 43vw;
        height: 43vw;
      }
      &:nth-child(6) {
        width: 51vw;
        height: 51vw;
      }
      &:nth-child(7) {
        width: 58vw;
        height: 58vw;
      }
      &:nth-child(8) {
        width: 68vw;
        height: 68vw;
      }
      &:nth-child(9) {
        width: 79vw;
        height: 79vw;
      }
      &:nth-child(10) {
        width: 90vw;
        height: 90vw;
      }
      &:nth-child(11) {
        width: 102vw;
        height: 102vw;
      }
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 3.2rem;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  &__button {
    z-index: 5;
  }

  .button-primary {
    &--white {
      background-color: white;
      color: #6429cd;
      padding: 5px 15px;
      // padding: 5px 30px;
  
      &:hover {
        box-shadow: 0px 0px 15px rgba(246, 247, 252, 0.8), inset 0px 0px 20px rgba(246, 247, 252, 0.2);
        cursor: pointer;
      }
    }
  }
}

@keyframes span {
  100% {
    transform: scale(2.5);
  }
}
