@import '../../../styles/media-queries';
@import '../../../styles/variables';

.error-boundary {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__tittle {
    color: $color-white;
    font-weight: 700;
    font-size:3rem;
    font-style: normal;
    text-align: center;
    line-height: 100%;
    margin: 1rem 0;
    width: 100%;

    @include laptop {
      font-size: 3.6rem;
    }
  }

  &__text {
    color: $color-white;
    text-align: center;
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 100%;
    width: 65%;

    @include laptop {
      font-size: 3.2rem;
      width: 52%;
    }
  }
}