@import '../../../styles/styles.scss';
@import '../../../styles/variables';

.container-alert {
  margin: 0;
}

.section-password-form {
  margin-bottom: 10px;
}

.modifier-accordion {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: -1rem 0;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-style: normal;
  color: white;
}

.anchor {
  display: flex;
  justify-content: left;
  color: #471e87;
  text-decoration: underline;
  cursor: pointer;
  font-size: $mobile-font;

  &.center {
    padding-top: 16px;
    justify-content: center;
  }

  &.disabled {
    display: none;
    color: #330072;
    opacity: 0.4;
    cursor: default;
  }

  @include from($x-large) {
    font-size: $sm-font;

    &.disabled {
      display: flex;
    }
  }
}

.disabled .accordion__input__text {
  color: #330072;
}

.btn-profile {
  width: auto !important;
  max-width: fit-content;
  height: 3.2rem;
  font-size: 1.4rem;

  @include laptop {
    width: 11.7rem;
    height: 4rem;
    font-size: 1.68rem;
  }
}

.profile-tabs {
  .form-tab {
    font-size: 1rem;
    gap: none;
    border: none;
    border-bottom: 2px solid #6429cd;
    border-radius: 0;
    padding: 24px;
    color: $color-purple;
    width: 100%;
    width: fit-content;
    text-wrap: nowrap;
    position: relative;

    &--selected {
      border: none;
      font-weight: 700;
      box-shadow: none;
    }

    &__icon {
      &--default {
        display: none;
      }
    }

    &__content {
      font-size: clamp(14px, 3vw, 16px);
    }
  }

  .TabPanel {
    &__tab-list {
      gap: 0;
      padding: 10px 2px 0 2px;
    }

    &__section {
      padding: 4rem clamp(1rem, 4vw, 5rem) 2rem clamp(1rem, 4vw, 5rem);
      display: flex;
      flex-direction: column;
    }
  }

  .tab__border-bottom {
    height: 7px;
    bottom: 0;
    border-radius: 50px;
    background-color: #6429cd;
    box-shadow: 0px -2px 15px -3px #6429cdcc, 0px 1px 30px 0px #6429cdcc;
  }
}

.sectionHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    font-weight: 400;
    color: #330072;
    padding: 16px 0px;
    line-height: 26px;
  }

  &__imgPen {
    margin-right: 10px;
  }
}

.sectionForm {
  input{
    &.disabled {
      background-color: transparent; 
      color: #6429CD; 
      pointer-events: none;
      padding: 0px;
      border: none; 
      opacity: 1;
      font-weight: 400;
      padding: 0px 8px 0px 20px;
    }
  }
}

.section-password-form {
  width: 100%;
  .change-password-form__button {
    display: none;
  }
  .input-password {
    width: 40vw;
    @media (max-width: 1280px) {
      width: 45vw;
    }
    @media (min-width: 1280px) {
      width: 30vw;
    }
  }
}

.section-password-form.disabled input {
  pointer-events: none; 
  background-color: #f5f5f5; 
  opacity: 0.6; 
}