@import 'styles/styles.scss';

.questionnaire__center{
  box-shadow: inset 0 0 10px 5px rgba(100, 41, 205, 0.6); 
}
.questionnaire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding: 71px 40px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1240px;
  transition: 0.3s ease;
  margin: auto auto;

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 450px;
    margin-top: 60px;
  }

  &__card {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 24px;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s;

    &--visible {
      opacity: 1;
      transform: translateX(0);
    }

    &--prev {
      transform: translateX(-100%);
      opacity: 0;
    }

    &--next {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 16px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__progress {
    display: flex;
    justify-content: space-between;
  }

  &__accountant {
    font-size: 16px;
    color: #330072;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #330072;
    width: 125px;
    color: white;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    border: none;
    border-radius: 100px;
    align-self: flex-end;
    margin-top: 25px;

    &:hover {
      box-shadow: 0px 0px 15px #6429cd,
        inset 0px 0px 0px rgba(189, 189, 255, 0.6);
      cursor: pointer;
    }

    &:disabled {
      background-color: #33007299;
      cursor: not-allowed;
      box-shadow: none;
      opacity: 0.6;
    }
  }

  &__title{
    font-size: 21px;
    display: flex;
    line-height: 30px;
    width: 696px;
    color: #330072;
  }

  @include laptop {
    padding: 50px 30px;
    gap: 40px;
    // margin: 40px auto;
  }

  @include auxiliaryDesktop {
    max-width: 1480px;
    padding: 80px 50px;
    gap: 60px;

    &__card{
      display: flex;
      gap: 230px;
    }
  }

  @media only screen and (max-width: 1024px) {
    max-width: 700px;
    padding: 40px 20px;
    gap: 30px;

    &__wrapper {
      height: 400px;
    }

    &__button {
      width: 100%;
      height: 45px;
      font-size: 14px;
    }
  }

  .fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999999999;   
    overflow: auto;
  }
}


.label{
  display: flex;
  align-items: center;
  gap: 12px;
  width: 460px;
  height: 72px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #330072;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  color: #330072;

  input[type="radio"]:not(:checked):hover {
    box-shadow: 0px 3px 8px 1px rgba(100, 41, 205, 0.8);
  } 
  
}


.footer__cont{
  margin: 0 auto;

  @include laptop {
    padding-inline: 5%;
  }

  @include desktop {
    padding-inline: 6.5%;
  }

  @include tablet {
    padding-inline: 4%;
    margin: 0 auto;
  }
}