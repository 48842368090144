@import 'styles/styles.scss';

.error-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 10vw;
  background: $background-gradient;
  

  &__span {
    display: hidden;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    position: absolute;
    animation: span 2s;
    animation-fill-mode: forwards;

    &-container{
      position: absolute;
      z-index: 1;
      display: flex;
      top: 0;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

      &:nth-child(1){
        width: 35vw;
        height: 35vw;
      }
      &:nth-child(2){
        width: 42vw;
        height: 42vw;
      }
      &:nth-child(3){
        width: 50vw;
        height: 50vw;
      }
      &:nth-child(4){
        width: 58vw;
        height: 58vw;
      }
      &:nth-child(5){
        width: 67vw;
        height: 67vw;
      }
      &:nth-child(6){
        width: 78vw;
        height: 78vw;
      }
      &:nth-child(7){
        width: 90vw;
        height: 90vw;
      }
      &:nth-child(8){
        width: 97vw;
        height: 97vw;
      }
      &:nth-child(9){
        width: 109vw;
        height: 109vw;
      }
      &:nth-child(10){
        width: 120vw;
        height: 120vw;
      }
      &:nth-child(11){
        width: 132vw;
        height: 132vw;
      }

    @include tablet {
      &:nth-child(1){
        width: 20vw;
        height: 20vw;
      }
      &:nth-child(2){
        width: 25vw;
        height: 25vw;
      }
      &:nth-child(3){
        width: 30vw;
        height: 30vw;
      }
      &:nth-child(4){
        width: 37vw;
        height: 37vw;
      }
      &:nth-child(5){
        width: 43vw;
        height: 43vw;
      }
      &:nth-child(6){
        width: 51vw;
        height: 51vw;
      }
      &:nth-child(7){
        width: 58vw;
        height: 58vw;
      }
      &:nth-child(8){
        width: 68vw;
        height: 68vw;
      }
      &:nth-child(9){
        width: 79vw;
        height: 79vw;
      }
      &:nth-child(10){
        width: 90vw;
        height: 90vw;
      }
      &:nth-child(11){
        width: 102vw;
        height: 102vw;
      }
    }
    
  }

  .error-boundary {  
    &__tittle {
      font-size:7rem;
      line-height: 145%;
      width: 100%;
  
      @include laptop {
        font-size: 9rem;
        line-height: 110%;
      }
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 3.2rem;
    width: 100%;
    
  }

  &__button{
    z-index: 5;
  }
}

@keyframes span {
  100%{
    transform: scale(2.5);
  }
}