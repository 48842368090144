@import '../../../styles/styles';

.profile-card {
  &__circle {
    width: 4.7rem;
    height: 4.7rem;
    border-radius: 50%;
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6429CD;

    @include tablet {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &__header {
    box-shadow: 0px 0px 15px 0px rgba(246, 247, 252, 0.80), 0px 0px 20px 0px rgba(246, 247, 252, 0.20) inset;
    background: #bdbdff;
    color: #6429cd;
    cursor: pointer;
    font-size: 21px;
    font-weight: 600;
  }
}

.logout-button {
  align-items: center;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 0.3rem;
  font-size: 14px;
  color: #330072;
  position: absolute;
  top: 110%;
  right: 0;
  justify-content: center;
  padding: 3px 10px;
  z-index: -1;

  &:hover {
    background: var(--primary-60-purple-primary-purple-500, #6429CD);
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(189, 189, 255, 0.60) inset, 0px 12px 24px 0px rgba(100, 41, 205, 0.60);
    color: white;

    img {
      filter: none;
    }

  }

  img {
    filter: brightness(0) saturate(100%) invert(16%) sepia(66%) saturate(4525%) hue-rotate(265deg) brightness(54%) contrast(123%);
    margin: 0;
    width: 23px;
    height: 32px;
    transform: scale(0.8);
  }

  p {
    white-space: nowrap;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}