@import '../../../styles/styles.scss';

.modal-large {
  backdrop-filter: blur(40px);
  background: #1F0D3F;
  border-radius: 25px;
  border: 1px solid $color-primary;
  box-shadow: 0px 8px 40px rgba(100, 41, 205, 0.6),
    inset 0px 0px 30px rgba(100, 41, 205, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  left: 0;
  max-height: 100vh;
  max-width: 100vw;
  padding: 3.2rem 2.3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 49.4rem;
  z-index: 10;
}

.modal-alert {
  align-items: center;
  backdrop-filter: blur(40px);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100vh;
  max-width: 100vw;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;

  &__container {
    position: relative;
    height: min-content;
    width: 90vw;
    background: #1F0D3F;
    border: 1px solid $color-primary;
    box-shadow: 0px 8px 40px rgba(100, 41, 205, 0.6),
      inset 0px 0px 30px rgba(100, 41, 205, 0.5);
    border-radius: 25px;
    padding: 2.2rem 2.3rem;

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include wideMobile {
      padding: 3.2rem 2.3rem;
    }

    @include tablet {
      width: 50vw;
      max-width: 30rem;
    }

    @include laptop {
      width: 25vw;
      max-width: 39.4rem;
      padding: 32px 23px;
    }

    @include laptop-height {
      width: 65vw;
    }
  }

  &__close {
    &-button {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      cursor: pointer;
      z-index: 2;

      img {
        margin: 0.2rem 0.2rem 0 0;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  &__link {
    z-index: -1;
  }
  &__animation {
    z-index: 1;
  }

  &__image {
    width: 5rem;
    height: 5rem;
    margin-bottom: 0;

    @include wideMobile {
      width: 8rem;
      height: 8rem;
      margin-bottom: 1rem;
    }

    &--error {
      filter: drop-shadow(0px 5px 20px rgba(237, 0, 57, 0.5));
    }

    &--success {
      filter: drop-shadow(0px 5px 20px rgba(0, 236, 165, 0.5));
    }
  }

  &__type {
    text-align: center;
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 2.5rem;
    color: $color-primary;
    margin-bottom: 2vh;
    padding-bottom: 0.5rem;
  }

  &__tittle {
    font-size: 2.1rem;
    line-height: 170%;
    color: $color-white;
    text-align: center;

    @include tablet {
      font-size: 3rem;
    }
  }
  &__custom-modal-text { 
    font-size: 30px !important;
    line-height: 45px;
  }
  &__text {
    color: $color-white;
    text-align: center;
    padding: 0.5rem;
    font-size: 1.2rem;
    line-height: 2rem;

    @include tablet {
      font-size: 1.6rem;
    }

    @include wideMobile {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  &__button {
    width: 11.2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    height: 3rem;
    font-size: 1.4rem;

    @include wideMobile {
      height: 4rem;
      font-size: 1.6rem;
    }

    &--cancel {
      width: 11.2rem;
      height: 4rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      background-color: transparent;
      color: white;
      font-size: 1.6rem;
      border-radius: 5rem;
      border: unset;
      transition: 0.3s;

      &:hover {
        box-shadow: unset;
        background: #bdbdff4d;
        cursor: pointer;
      }
    }
  }

  &__buttons-wrap {
    display: flex;
    gap: 1.6rem;
  }

  &__input {
    padding: 0;
  }

  &__header {
    color: white;
    background-color: $color-primary;
    border-radius: 24px 24px 0 0;
    margin-bottom: 10px;
    padding: 1px;
    text-align: center;
    h1 {
      font-weight: 600;
    }
  }

  &__ContenedorLink {
    height: 88px;
    width: 95%;
    display: inline-grid;

    &__labelLink {
      font-weight: 700;
      font-size: 14px;
      color: white;
      line-height: 19.6px;
      font-family: 'Poppins';
    }

    &__inputLink {
      color: white;
      height: 40px;
      width: 100%;
      padding: 10px 12px;
      border-radius: 100px;
      opacity: 60%;
      background: rgba(100, 41, 205, 0.08);
      border: 1px solid #6429cd;
    }
  }
}

.sizeText {
  color: $color-white;
  text-align: center;
  padding: 0.5rem;
  font-size: 30px;
}
