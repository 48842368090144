@import '../../../../styles/styles.scss';

.container-loader {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include laptop {
    height: 45vh;
  }

  @include desktop {
    height: 60vh;
  }
}
